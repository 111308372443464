// Based on https://merakiui.com/
import React, { useState } from "react";
import { Link } from "gatsby";
import Fuse from "fuse.js";
import fundlist from "../data/fundsearchindex.json";
const fuse = new Fuse(fundlist, { keys: ["a"] });

const SearchResult = ({ searchResult, setSearchTerm }) => {
  let result = [];
  for (let i = 0; i < Math.min(searchResult.length, 7); i++) {
    const item = searchResult[i];
    result.push(
      <Link
        to={`/fund/${item.item.abbr}`}
        key={item.item.abbr}
        onClick={() => setSearchTerm("")}
        onMouseDown={(e) => e.preventDefault()}
      >
        {/* onMouseDown preventDefault to allow link clicking before onBlur 
        https://stackoverflow.com/a/36691651 */}
        <span className="block py-2">
          <h3 className="font-medium text-gray-700 dark:text-gray-100 hover:underline">
            {item.item.status === 1 && (
              <svg
                className="inline align-middle h-5 w-5 text-green-400 mr-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {item.item.status === 2 && (
              <svg
                className="inline align-middle h-5 w-5 text-blue-400 mr-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {item.item.status === 0 && (
              <svg
                className="inline align-text-top h-5 w-5 text-gray-400 mr-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {item.item.abbr}
          </h3>
          {/* <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Second Line
          </p> */}
        </span>
      </Link>
    );
  }
  return (
    <>
      {result.length > 0 && (
        <div className="z-10 absolute inset-x-0 px-6 py-3 mt-2 overflow-y-auto bg-white border border-gray-300 rounded-md max-h-80 dark:bg-gray-800 dark:border-transparent">
          {result}
        </div>
      )}
    </>
  );
};

export default function Search() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [showSearchResult, setShowSearchResult] = useState(false);

  const handleChange = (event) => {
    const targetValue = event.target.value;
    setSearchTerm(targetValue);
    setSearchResult(fuse.search(targetValue));
    setShowSearchResult(true);
  };

  return (
    <>
      <div className="relative">
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            className="w-5 h-5 text-gray-400"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </span>

        <input
          type="text"
          className="w-full py-3 pl-10 pr-4 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          placeholder="ค้นหากองทุน"
          value={searchTerm}
          onChange={handleChange}
          onBlur={() => setShowSearchResult(false)}
          onFocus={() => setShowSearchResult(true)}
        />
        {showSearchResult && (
          <SearchResult
            searchResult={searchResult}
            setSearchTerm={setSearchTerm}
          />
        )}
      </div>
    </>
  );
}
